.App {
  text-align: center;
}

.rotate {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.keep-scrolling::-webkit-scrollbar {
  display: none;
}
.keep-scrolling * {
  overflow-anchor: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.keep-scrolling {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.content {
  height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
  overflow-y: scroll;
}

/* example-split.css */
.split-horizontal {
  display: flex;
  width: 100%;
  height: 100%;
}
.split-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gutter {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 4px !important;
  background: #5d6b74c2;
}
.gutter-horizontal {
  cursor: col-resize;
}
.gutter-vertical {
  cursor: row-resize;
}
.gutter:hover {
  background: rgb(60, 60, 60);
}
.gutter-dragging:hover {
  background: rgb(60, 60, 60);
}

.pane {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

/* app propsal message */

.propose_to {
  color: #3a3939;
  text-decoration: underline;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.propose_sender {
  color: black;
  margin-bottom: 0.5rem;
}

.propose_details {
  color: #0054a3;
  margin: 0.5rem 0;
}

.propose_file {
  color: #3a3939;
  margin-top: 1rem;
}

.propose_name {
  font-weight: bold;
  color: #b80000;
}

/* chat */
.message {
  position: relative;
  background: #eeeeee;
  border-radius: 6px;
  display: inline-block;
  padding: 1.8rem 1rem 0.8rem 1rem;
  max-width: 70%;
  min-width: 2rem;
}
.message:after {
  content: "";
  position: absolute;
  left: 0;
  top: 70%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #eeeeee;
  border-left: 0;
  border-bottom: 0;
  margin-top: -5px;
  margin-left: -10px;
}
.message.right {
  background: #00cc80;
  color: white;
}
.message.right:after {
  content: "";
  position: absolute;
  left: calc(100% + 10px);
  top: 70%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #00cc80;
  border-right: 0;
  border-bottom: 0;
  margin-top: -5px;
  margin-right: -10px;
}
